<template>
  <b-form-group label="Cimkék" v-if="visibleCimkek.length">
    <ul class="list-group dense scrollable-list">
      <li
        v-for="cimke in visibleCimkek"
        :key="cimke.Id"
        class="list-group-item d-flex justify-content-between align-items-center hidden-container"
      >
        <div class="d-flex align-items-center flex-fill">
          <k-file-select
            accept="image/*"
            :block="false"
            class="mr-2"
            @inputBase64="cimke.Kep = $event[0]"
          >
            <img
              class="li-img border-radius-6"
              :src="cimke.Kep ? cimke.Kep + '?h=30' : $placeholderImage"
            />
          </k-file-select>
          <k-inline-text-edit :value.sync="cimke.Nev" :text="cimke.Nev">
          </k-inline-text-edit>
        </div>
        <div
          class="li-tile mr-2 pointer hidden-item"
          @click="RemoveCimke(cimke)"
          v-b-tooltip="'Törlés'"
        >
          <i class="fa fa-trash"></i>
        </div>
      </li>
      <!--<li
        class="list-group-item d-flex justify-content-start align-items-center pointer sticky-bottom"
        @click="UjCimke"
      >
        <div class="li-tile grey mr-2">
          <i class="fa fa-plus"></i>
        </div>
        <span>
          Új cimke
        </span>
      </li>-->
    </ul>
  </b-form-group>
</template>

<script>
  /* eslint vue/no-mutating-props: 0 */

export default {
  name: 'pcf-cimkek',
  data() {
    return {
      ct: -1,
    };
  },
  mounted() {},
  created() {},
  methods: {
    async UjCimke() {
      let id = this.ct--;
      this.form.Cimkek.push({
        Id: id,
        Nev: `cimke${id}`,
        Kep: null,
        ToroltFl: false,
      });
      await this.$nextTick();
      this.$el.getElementsByClassName('scrollable-list')[0].scrollTop =
        Number.MAX_SAFE_INTEGER;
    },
    RemoveCimke(cimke) {
      if (cimke.Id > 0) {
        let prev = this.form.Cimkek.find(f => f.Id == cimke.Id);
        this.$set(prev, 'ToroltFl', true);
      } else {
        this.form.Cimkek = this.form.Cimkek.filter(
          f => f.Id != cimke.Id
        );
      }
    },
  },
  computed: {
    visibleCimkek() {
      return this.form.Cimkek.filter(f => !f.ToroltFl);
    },
  },
  watch: {},
  props: {
    form: {
      requied: true,
    },
  },
};
</script>
